<template>
  <highcharts
    :options="options"
  />
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import stockInit from 'highcharts/modules/stock';

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: 'Highcharts',
  props: ['chartData'],
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 600,
        },
        title: { text: '' },
        subtitle: { text: '' },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500,
              height: 500,
            },
          }],
        },
        yAxis: { title: { text: '' } },
        xAxis: {
          title: { text: '' },
          categories: [],
        },
        credits: {
          enabled: false,
        },
        exporting: {
          sourceWidth: 1200,
        },
        series: [],
      },
    };
  },
  computed: {
    options() {
      return { ...this.chartOptions, ...this.chartData };
    },
  },
};
</script>
